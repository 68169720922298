import React from "react";
import {
  Grid,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import Desktop from "../Asserts/mainBg.svg";
import logo from "../Asserts/White_Logo 1.svg";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Lottie from "lottie-react";
import rocketAnimation from "../Asserts/Animation - Rocket.json";
import mobileAnimation from "../Asserts/Flow 5 (1).json";

const WelcomePage = () => {
  const targetDate = new Date("2024-12-07T10:00:00Z").getTime();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100%",
        backgroundImage: `url(${Desktop})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        backgroundColor: isMobile ? "#253237" : "transparent",
        padding: { xs: 2, sm: 3, md: 5 },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: { xs: 10, sm: 20 },
          left: { xs: 10, sm: 20 },
          zIndex: 2,
          width: { xs: "120px", sm: "200px", md: "300px" },
          height: { xs: "30px", sm: "50px", md: "80px" },
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ width: "100%", height: "auto", maxWidth: "100%" }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: { xs: 10, sm: 20 },
          right: { xs: 10, sm: 20 },
          display: "flex",
          gap: 1,
        }}
      >
        <a href="https://www.avvtar.ai/privacy/privacy.html">
          <Button
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: 2,
              padding: "8px 16px",
              textTransform: "none",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              backgroundColor: "#FF7F27",
              "&:hover": {
                backgroundColor: "#e06600",
              },
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            }}
          >
            Privacy
          </Button>
        </a>
        <a href="https://www.avvtar.ai/support/support.html">
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: 2,
              padding: "8px 16px",
              textTransform: "none",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              backgroundColor: "#FF7F27",
              "&:hover": {
                backgroundColor: "#e06600",
              },
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            }}
          >
            Support
          </Button>
        </a>
      </Box>

      {!isMobile ? (
        <Grid container spacing={5}>
          <Grid item xs={12} sm={7}>
            <Box
              sx={{
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
                fontWeight: "bold",
                padding: { xs: "10px", sm: "20px", md: "30px" },
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                color: "#FFF",
              }}
            >
              <Typography
                variant="inherit"
                component="span"
                sx={{ color: "#FF7F27" }}
              >
                The Future{" "}
              </Typography>
              <Typography
                variant="inherit"
                component="span"
                sx={{ color: "#DCC1F3" }}
              >
                is about
              </Typography>
              <br />
              <Typography
                variant="inherit"
                component="span"
                sx={{ color: "#DCC1F3" }}
              >
                to unfold
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", zIndex: 1, mt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <Lottie
                  animationData={rocketAnimation}
                  style={{ width: 150, height: 150 }}
                />
                <Box sx={{ mt: { xs: 2, sm: 0 }, ml: { sm: 3 } }}>
                  <Box sx={{ display: "inline-block" }}>
                    <FlipClockCountdown
                      to={targetDate}
                      labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                      labelStyle={{ color: "#FFF", fontSize: 14 }}
                      digitBlockStyle={{
                        width: 30,
                        height: 50,
                        fontSize: 40,
                        background: "#FF7F27",
                        color: "#1B1B1B",
                        border: "2px solid #000",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Animation */}
          <Grid item xs={12} sm={5}>
            <Box sx={{ textAlign: "center" }}>
              <Lottie
                animationData={mobileAnimation}
                style={{ width: "100%", maxWidth: "500px", margin: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Lottie
                animationData={mobileAnimation}
                style={{ width: "80%", maxWidth: "350px", margin: "auto" }}
              />
            </Box>
            <Box
              sx={{
                fontSize: { xs: "2.5rem", sm: "2rem" },
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                color: "#FFF",
                marginTop: "20px",
                // width: "90%",
              }}
            >
              <Typography
                variant="inherit"
                component="span"
                sx={{ color: "#FF7F27" }}
              >
                The Future is{" "}
              </Typography>
              <br />
              <Typography
                variant="inherit"
                component="span"
                sx={{ color: "#DCC1F3" }}
              >
                about to unfold
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Lottie
                  animationData={rocketAnimation}
                  style={{ width: 80, height: 80 }}
                />
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ display: "inline-block" }}>
                    <FlipClockCountdown
                      to={targetDate}
                      labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                      labelStyle={{ color: "#FFF", fontSize: 12 }}
                      digitBlockStyle={{
                        width: 28,
                        height: 40,
                        fontSize: 30,
                        background: "#FF7F27",
                        color: "#1B1B1B",
                        border: "2px solid #000",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Copyright Text */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
          width: "100%",
          color: "#FFF",
          fontSize: { xs: "0.7rem", sm: "0.8rem", md: "1rem" },
          fontFamily: "Raleway, sans-serif",
          mt: 2,
          mb: 2,
        }}
      >
        &copy; {new Date().getFullYear()} avvtar.ai. All Rights Reserved |
        Powered by SPYKK, Delivered by PRAKAT
      </Box>
    </Grid>
  );
};

export default WelcomePage;
